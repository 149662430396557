<template>
  <PageLayout :headerText="headerText">
    <template #main> </template>
  </PageLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// components
import PageLayout from "@/components/PageLayout.vue";

export default defineComponent({
  name: "Test",
  components: {
    PageLayout
  },
  setup() {
    const headerText = "Test Page";

    return {
      headerText
    };
  }
});
</script>
